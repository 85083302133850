import React, { createContext, useState, useContext, useEffect } from "react";

import useWebSocket from "react-use-websocket";
import { useAuth } from "./AuthProvider";

import { wsUrl } from "../config";

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const { getUserNotifications, token } = useAuth();
  const [messages, setMessages] = useState([]);

  const { lastMessage } = useWebSocket(wsUrl, {
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 2000,
    reconnectAttempts: 20,
    share: true,
    onOpen: () => console.log("WS Connection opened"),
    onClose: () => console.log("WS Connection closed"),
  }, !!token);

  const addMessage = (content, color) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { content: content, color: color },
    ]);
  };

  const removeMessage = (index) => {
    setMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      const payload = JSON.parse(data.message);

      addMessage(payload.text, payload.color, payload.type, payload.title);
      getUserNotifications();
    }
  }, [lastMessage]);

  return (
    <MessageContext.Provider value={{ messages, addMessage, removeMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => useContext(MessageContext);
