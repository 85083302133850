import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";

const PageError = ({ errCode = "NOTFOUND" }) => {
  const errCodesMap = {
    NOTFOUND: {
      code: 404,
      title: "Страница не найдена",
      description: "Запрашиваемая старница не существует или перемещена",
    },
    NOTPERMITTED: {
      code: 403,
      title: "Доступ запрещен",
      description:
        "Ваша учетная запись не имеет прав на просмотр данного раздела",
    },
    INTERROR: {
      code: 500,
      title: "Куда-то нажали и все пропало!",
      description: "Внутренняя ошибка сервера",
    },
    NOCONN: {
      code: 504,
      title: "Сервер не отвечает",
      description: "Система работает в автономном режиме",
    },
  };

  return (
    <div className="bg-transparent min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <span className="clearfix">
              <h1 className="float-start display-3 me-3">
                {errCodesMap[errCode].code}
              </h1>
              <h4 className="mt-2">
                {errCodesMap[errCode].title}
              </h4>
              <p className="text-medium-emphasis float-start">
                {errCodesMap[errCode].description}
              </p>
            </span>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default PageError;
