import { CRow, CCol, CContainer, CSpinner } from "@coreui/react";
import React from "react";

const PageLoading = ({ text = "Загрузка", fullHeight = true }) => {
  return (
    <div
      className={`bg-transparent d-flex flex-row align-items-center${
        fullHeight ? " min-vh-100" : ""
      }`}
    >
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={12} className="text-center">
            <CSpinner color="primary" />
            <br />
            <strong>{text}</strong>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
export default PageLoading;
