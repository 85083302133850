import React, { Suspense } from "react";
import { CContainer, CToaster } from "@coreui/react";

import { Route, Routes } from "react-router-dom";

import useScreenType from "react-screentype-hook";
import PrivateRoute from "../hooks/PrivateRoute";
import { useMessage } from "../hooks/MessageProvider";
import { useAuth } from "../hooks/AuthProvider";

import Header from "../components/header/Header";
import PageLoading from "../components/pages/PageLoading";
import Message from "../components/Message";
import LoginView from "../views/accounts/LoginView";

import { routes } from "../routes";

import "../scss/style.scss";
import russia from "../assets/russia.png";
import { useSelector } from "react-redux";

const DefaultLayout = () => {
  const auth = useAuth();
  const { messages } = useMessage();
  const screenType = useScreenType();
  const fullScreen = useSelector(state => state.fullScreen)

  const routesComponents = routes.map((route) => (
    <Route
      key={route.name}
      path={route.path}
      element={
        <PrivateRoute
          permission={route.permission}
          allowOffline={route.allowOffline}
        >
          <route.element />
        </PrivateRoute>
      }
    />
  ));

  const messagesComponents = messages.map((message, idx) => (
    <Message idx={idx} key={idx} text={message.content} color={message.color} />
  ));

  const wrapperStyle = !screenType.isMobile
    ? {
        backgroundImage: `url(${russia})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: screenType.isTablet
          ? "center bottom"
          : "right bottom",
        backgroundAttachment: "fixed",
      }
    : {};

  if (auth.userIsLoading) {
    return <PageLoading text="Инициализация приложения" />;
  }

  return (
    <>
      <div
        className="wrapper d-flex flex-column min-vh-100 bg-transparent"
        style={wrapperStyle}
      >
        {auth.token && <Header />}
        {messagesComponents.length > 0 && (
          <CToaster
            className={`px-3 pt-5 position-fixed top-0 end-0${
              screenType.isMobile ? " w-100" : ""
            }`}
          >
            {messagesComponents}
          </CToaster>
        )}

        <Suspense fallback={<PageLoading />}>
          <CContainer fluid={fullScreen}>
            <Routes>
              <Route path="/login" element={<LoginView />} />
              {routesComponents}
            </Routes>
          </CContainer>
        </Suspense>
      </div>
    </>
  );
};

export default DefaultLayout;
