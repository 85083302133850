import React from "react";
import {
  CContainer,
  CHeader,
  CHeaderNav,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CSpinner,
} from "@coreui/react";

import useScreenType from "react-screentype-hook";
import { useAuth } from "../../hooks/AuthProvider";

import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";

import HeaderItem from "./components/HeaderItem";
import UserHeaderItem from "./components/UserHeaderItem";
import NotificationsHeaderItem from "./components/NotificationsHeaderItem";

import { navbarItems } from "../../routes";
import FullScreenHeaderItem from "./components/FullScreenHeaderItem";

const Header = () => {
  const auth = useAuth();
  const screenType = useScreenType();

  const items = navbarItems
    .filter((item) => auth.hasPerms(item.permission))
    .map((item, idx) => (
      <HeaderItem
        key={idx}
        item={item}
        isMobile={screenType.isMobile || screenType.isTablet}
        isLastItem={idx + 1 === navbarItems.length}
      />
    ));

  return (
    <CHeader position="sticky" className="mb-4 rounded-bottom-4 shadow">
      <CContainer fluid>
        {screenType.isMobile || screenType.isTablet ? (
          <CDropdown>
            <CDropdownToggle
              color="transparent"
              size="sm"
              className="text-secondary"
              caret={false}
            >
              <Icon path={mdiMenu} size={1} />
            </CDropdownToggle>
            <CDropdownMenu className="rounded-3">{items}</CDropdownMenu>
          </CDropdown>
        ) : (
          <CHeaderNav className="d-none d-md-flex me-auto">
            {auth.userIsLoading ? <CSpinner size="sm" /> : items}
          </CHeaderNav>
        )}
        <CHeaderNav className="ms-auto">
          {!screenType.isMobile && (
            <>
              <FullScreenHeaderItem />
              <li className="nav-item py-1">
                <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
              </li>
            </>
          )}
          <NotificationsHeaderItem />
          {!screenType.isMobile && (
            <li className="nav-item py-1">
              <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
            </li>
          )}
        </CHeaderNav>
        <CHeaderNav>
          <UserHeaderItem />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default Header;
