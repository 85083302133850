import React from "react";
import { CToast, CToastBody, CToastClose } from "@coreui/react";

import { useMessage } from "../hooks/MessageProvider";
import useScreenType from "react-screentype-hook";

const Message = ({ idx, text, color, timeout = 10000 }) => {
  const { removeMessage } = useMessage();
  const screenType = useScreenType()

  const onClose = () => {
    removeMessage(idx);
  };

  return (
    <CToast
      autohide={true}
      visible={true}
      index={idx}
      onClose={onClose}
      color={color}
      delay={timeout}
      className={`text-white align-items-center rounded-3${
        screenType.isMobile ? " w-100" : ""
      }`}
    >
      <div className="d-flex">
        <CToastBody>{text}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  );
};

export default Message;
