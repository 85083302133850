import { mdiAlert, mdiBell, mdiChatOutline } from "@mdi/js";

const proto = window.location.protocol;
const host = window.location.hostname;
const port = window.location.port === "3000" ? "8000" : window.location.port;
const usingHttps = proto.toLowerCase().endsWith("s:");

export const backendHost = `${proto}//${host}${port ? `:${port}` : ""}`;
export const wsUrl = `${usingHttps ? "wss" : "ws"}://${host}${
  port ? `:${port}` : ""
}/ws/socket-server/`;

console.log(`using HTTPS: ${usingHttps}`);

export const selectControlStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#a8d0b6" : "#9da5b1",
    boxShadow: state.isFocused ? "0 0 0 0.25rem rgba(202,237,213,255)" : null,
    borderRadius: "0px",
    width: "100%",
    "&:hover": {
      borderColor: state.isFocused ? "#a8d0b6" : "#9da5b1",
    },
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: "0px",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: "150px",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isSelected ? "#2eb85c" : "#fff",
  }),
};

export const alertTypesIconMap = {
  common_alert: mdiBell,
  chat_alert: mdiChatOutline,
  system_alert: mdiAlert,
};

export const modelMap = {
  tasklink_set: "Ссылка",
  // taskimage_set: "Изображение",
  // taskdocument_set: "Документ",
};

export const modelOptions = Object.entries(modelMap).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const condtionMap = {
  links_count: "Количество ссылок",
  links_valid_count: "Количество валидных ссылок",
  // images_count: "Количество изображений",
};

export const conditionOptions = Object.entries(condtionMap).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
);

export const operatorMap = {
  eq: "должно быть равно",
  ge: "должно быть больше или равно",
  le: "должно быть меньше или равно",
  ne: "должно быть не равно",
  gt: "должно быть больше",
  lt: "должно быть меньше",
};

export const operatorOptions = Object.entries(operatorMap).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
);

export const filterOperationMap = {
  contains: "Содержит",
  startswith: "Начинается с",
  endswith: "Заканчивается на",
  eq: "Точно"
}

export const filterOperationOptions = Object.entries(filterOperationMap).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
);

export const unitMap = {
  count: "единиц",
  percentage: "процентов",
};

export const unitOptions = Object.entries(unitMap).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const yesNoMap = {
  true: "Да",
  false: "Нет"
}

export const yesNoOptions = Object.entries(yesNoMap).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const imageTypeMap = {
  "vk.com": "vk.com",
  "ok.ru": "ok.ru",
  "telegram.org": "telegram.org"
}

export const imageTypeOptions = Object.entries(imageTypeMap).map(([key, value]) => ({
  label: value,
  value: key,
}));


export const feedbackTemplateOptions = [
  "Отчет не отображает выполнение задачи",
  "Отсутствует развернутое мнение по теме",
  "Страница пользователя вызывает подозрение",
  "Копирование тз",
  "Дубликат",
  "Другое",
].map((option) => ({ label: option, value: option }));

export const settingsFieldsTypeMap = {
  bool: "switch",
  date: "date",
  datetime: "datetime-local",
  decimal: "number",
  email: "email",
  file: "file",
  float: "number",
  image: "file",
  int: "number",
  json: "textarea",
  string: "text",
  text: "textarea",
  url: "url",
};
