import { useEffect, useState } from "react";

import { useMessage } from "./MessageProvider";
import { useConnection } from "./ConnectionProvider";

import fileDownload from "js-file-download";

import api from "../utils/api";


export const useErrorHandler = () => {
  const { addMessage } = useMessage();

  const handleError = (err) => {
    let errorText = "Неизвестная ошибка";

    if (err?.response?.data?.detail) {
      errorText = err.response?.data?.detail;
    }
    if (err?.request && err?.request?.data?.status >= 500) {
      errorText = "Внутренняя ошибка сервера";
    }
    addMessage(errorText, "danger");
  };

  return { handleError };
};

export const useFetch = (url = null, defaultFilters = null) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();

  const fetch = ({ url, filters, showLoading = true }) => {
    showLoading && setIsLoading(true);
    api
      .get(url, {params: filters})
      .then((res) => {
        setData(res.data.results);
        setCount(res.data.count);
        showLoading && setIsLoading(false);
      })
      .catch((err) => {
        showLoading && setIsLoading(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
      });
  };

  useEffect(() => {
    url && fetch({ url: url, filters: defaultFilters || {} });
  }, [url]);

  return { data, count, isLoading, hasErrors, denied, fetch };
};

export const useRetrieve = (defaultUrl = null) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();

  const retrieve = ({
    url = null,
    showLoading = true,
    onSuccess = null,
    onFail = null,
  }) => {
    showLoading && setIsLoading(true);
    api
      .get(url || defaultUrl)
      .then((res) => {
        setData(res.data);
        showLoading && setIsLoading(false);
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        showLoading && setIsLoading(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
        onFail && onFail(err);
      });
  };

  useEffect(() => {
    defaultUrl && retrieve({ url: defaultUrl });
  }, [defaultUrl]);

  return { data, isLoading, hasErrors, denied, retrieve };
};

export const useFileRetrieve = () => {
  const [inProcess, setInProcess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();
  const { addMessage } = useMessage();

  const fileRetrieve = ({
    url,
    filename,
    showLoading = true,
    params = null,
    fileFetch = true,
  }) => {
    const queryParams = params
      ? { ...params, background_export: !fileFetch }
      : { background_export: !fileFetch };

    showLoading && setInProcess(true);
    api
      .get(url, {
        params: queryParams,
        responseType: fileFetch ? "blob" : "json",
      })
      .then((res) => {
        // Это конечно выглядит так себе, но работает малой кровью
        if (fileFetch) {
          fileDownload(res.data, filename);
        } else {
          addMessage(res.data.detail, "success");
        }

        showLoading && setInProcess(false);
      })
      .catch((err) => {
        showLoading && setInProcess(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
      });
  };
  return { inProcess, hasErrors, denied, fileRetrieve };
};

export const useDestroy = (baseUrl = null) => {
  const [inProcess, setInProcess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();
  const { addMessage } = useMessage();

  const destroy = ({
    id,
    path = "delete",
    successText = "Объект удален",
    params = null,
    onSuccess = null,
    onFail = null,
  }) => {
    setInProcess(true);
    const url = id ? `${baseUrl}/${id}/${path}` : `${baseUrl}/${path}`;
    api
      .delete(url, { params: params && params })
      .then((res) => {
        setInProcess(false);
        addMessage(successText, "success");
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        setInProcess(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
        onFail && onFail(err);
      });
  };
  return { inProcess, hasErrors, denied, destroy };
};

export const useSave = (baseUrl) => {
  const [inProcess, setInProcess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();
  const { addMessage } = useMessage();

  const save = ({
    data,
    id = null,
    path = null,
    successText = "Изменения сохранены",
    showSuccessAlert = true,
    onSuccess = null,
    onFail = null,
  }) => {
    let response;
    setInProcess(true);
    if (id) {
      response = api.patch(`${baseUrl}/${id}/${path ? path : ""}`, data);
    } else {
      response = api.post(`${baseUrl}/${path ? path : ""}`, data);
    }
    response
      .then((res) => {
        setInProcess(false);
        showSuccessAlert &&
          addMessage(
            typeof successText === "function" ? successText(res) : successText,
            "success"
          );
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        setInProcess(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
        onFail && onFail(err);
      });
  };
  return { inProcess, hasErrors, denied, save };
};