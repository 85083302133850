import React from "react";

import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";

import PageError from "../components/pages/PageError";

const PrivateRoute = ({ children, permission }) => {
  const { token, hasPerms } = useAuth();
  const location = useLocation();

  if (!token) {
    const url =
      location.pathname && location.pathname !== "/login"
        ? `/login?next=${location.pathname}`
        : "/login";

    return <Navigate to={url} replace />;
  }

  if (!hasPerms(permission) && permission !== "allow_any") {
    return <PageError errCode="NOTPERMITTED" />;
  }

  return children;
};

export default PrivateRoute;
